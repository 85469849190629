import React from 'react';

import Layout from '../../components/Layout';
import Service from '../../components/Service';

import Image from '../../../assets/images/services/digital.jpg';

const col1 = [
  'High quality, long-life labels',
  'CMYK Print',
  'No need for originations, plates or cutters',
  'Waterproof - suitable for interior and exterior use'
];

const col2 = [
  'Cost effective for small/medium print runs',
  'Photo quality print',
  'Label size from 3mm x 3mm to 1250mm x 5000mm',
  'Halftone and screens available'
];

const Digital = () => {
  const title = 'Digital Labels';

  return (
    <Layout title={title}>
      <Service
        tag="Calf Hey Rotary"
        title={title}
        description="High quality, long life, full colour labels without the need for expensive originations. Special and intricate shapes cut without the need for expensive cutters. Very cost effective.<br /><br />The labels are exterior grade, have a long life (up to 5 years) and are light fast in normal UK conditions."
        image={Image}
        col1={col1}
        col2={col2}
        artwork="<strong>ARTWORK FOR DIGITAL</strong> - almost any format is accepted, but high resolution .pdf is preferred"
      />
    </Layout>
  );
};

export default Digital;
